import forList from "@/mixin/forList";

export default {
    name: "RegularStatistics",
    mixins: [forList],
    noSaveState: 1,     // 不管理本页的查询状态，但是需要清除其他列表保存的查询状态
    noFetchFirst: true, // 进入页面后，不自动获取第一页的数据

    data() {
        return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: {
                startTime: "",  // 开始时间
                endTime: "",    // 结束时间
                curTypeIndex: -1
            },
            SP: {
                curTypeIndex: -1
            },
            exporting: false,   // 导出中

            typeList: [         // 查询类型
                {
                    name: "注册客户统计",
                    value: 8,
                    url: "api/customer/getRegisterStatistics",
                    // exportUrl: "api/download/exportCustomerRegister",
                    disabled: false,
                    auth: {}
                },
                {
                    name: "订单数统计",
                    value: 9,
                    url: "api/order/getOrderStatistics",
                    // exportUrl: "api/download/exportOrderStatisticsr",
                    disabled: false,
                    auth: {}
                },
                {
                    name: "成交客户推荐客户分析",
                    value: 10,
                    url: "api/customer/getDealCustStatistics",
                    // exportUrl: "api/customer/getRegisterStatistics",
                    disabled: false,
                    auth: {}
                }
            ]

        };
    },

    computed: {

        curType () {
            return this.typeList[this.SP.curTypeIndex] || {auth: {}}
        },

        buttonFreezing () {
            return this.loading || this.exporting
        }

    },

    methods: {

        fetchData() {
            const params = this.extractParams(true);
            delete params.curTypeIndex;
            if (!params.startTime && !params.endTime) return this.$message({
                message: "请输入日期再查询",
                type: "warning"
            });

            if (this.loading) return;
            this.loading = true;
            this.tableData.splice(0, this.tableData.length)
            this.$axios({
                url: this.curType.url,
                params
            })
            .then(res => {
                if (res.code === 2000 && res.data.list) {
    
                    const list = res.data.list.filter(item => !!item);
                    this.tableData.push(...list)

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
        },

        // 点击导出
        handleExportBtn () {
            const params = this.extractParams(true);
            const {startTime, endTime, curTypeIndex} = params;
            delete params.curTypeIndex;
            if (!startTime && !endTime) {
                return this.$message({
                    message: "请选择查询的时间段",
                    type: "warning"
                })
            }
            
            this.$confirm("确认导出当前条件下的统计数据吗？")
            .then(() => {
                this.exporting = true;
                return this.$oxios({
                    url: "api/download/exportStatisticsr",
                    method: "post",
                    data: {
                        content: JSON.stringify(params),
                        type: this.typeList[curTypeIndex].value
                    }
                })
            })
            
            .then(res => {
                const success = 2000 == res.code;
                this.$message({
                    message: success ? "已加入导出任务，去消息管理的下载通知中查看吧" : res.msg || "导出失败",
                    type: success ? "success" : "warn"
                });
            })

            .catch(reason => {
                // this.$confirm拒绝结果是值 false，对于拒绝的情况
                // ，不用 message 提示，因为表单控件上已有提示
                (reason == "cancel") || this.$message({
                    message: reason || "导出失败",
                    type: "error"
                });
                console.warn(reason);
            })

            .then(() => {
                this.exporting = false;
            })

        },

    },

    created () {
        const {typeList} = this;
        let i = 0, len = typeList.length;
        for (; i < len; i++) {
            const typeOpt = typeList[i];
            typeOpt.auth.get = this.$hasAuthFor(typeOpt.url)
            // typeOpt.auth.export = this.$hasAuthFor(typeOpt.exportUrl)

            // 如果disabeld没有初始的的值，或初始值为否，根据这个类型的列表get权限赋值
            if (typeOpt.disabled == undefined || !typeOpt.disabled)
                typeOpt.disabled = !typeOpt.auth.get;
            
            // 如果当前没有选中，就设置一个没有被disabeld的值
            // 如果当权选中的是一个被disabled的值，就选中的下一个
            if (this.SP_.curTypeIndex < 0) this.SP_.curTypeIndex = typeOpt.disabled ? i + 1 : i;
            else if (this.SP_.curTypeIndex === i && typeOpt.disabled) this.SP_.curTypeIndex = i + 1;
        }
        this.updateSP_toSP(); // 将选中的类型值同步
        this.auth.export = this.$hasAuthFor("api/download/exportStatisticsr")
    }
};